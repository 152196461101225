<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      <b>Attendance Logs</b> <br>
      <small>Date: {{ dateFormat(date) }} - Student: {{ student_number }}</small> <br>
      <small>
        Last Edition: {{ dateTimeFormat(attEnrolment.updated_at) }}
        by {{ attEnrolment.updater.name }}
      </small>
    </template>
    <template slot="body">
      <p
        v-for="(l,i) of logs"
        :key="i"
      >
        <b>LOG #{{ i+1 }}:</b> <small>{{ dateTimeFormat(l.record_time, true) }}</small>
      </p>
    </template>
    <template slot="footer">
      <md-button
        class="md-danger md-sm"
        @click="close"
      >
        Close
      </md-button>
    </template>
  </vue-modal>
</template>
<script>

export default {
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    student_number: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    attEnrolment: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    logs: [],
  }),
  mounted() {
    this.getLogs();
    // send menu to background
    const sbar = document.querySelector('.sidebar');
    sbar.style.zIndex = 0;
  },
  beforeDestroy() {
    // send menu to origin state
    const sbar = document.querySelector('.sidebar');
    sbar.style.zIndex = 9999;
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    getLogs() {
      this.request(`biometric/logs/${this.student_number}`,
        'get',
        { date: this.date },
        ({ data }) => {
          this.logs = data;
        });
    },
  },
};
</script>
<style></style>
