<template>
  <div>
    <letters-index
      v-if="!letterToEdit"
      :programme-model="programmeModel"
      @onEditLetter="onEditLetter"
    />

    <letters-editor
      v-else
      :letter="letterToEdit"
      @close="closeEditor"
    />
  </div>
</template>

<script>
import LettersEditor from './Letters/LettersEditor.vue';
import LettersIndex from './Letters/LettersIndex.vue';

export default {
  components: {
    LettersEditor,
    LettersIndex,
  },
  props: {
    programmeModel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      letterToEdit: null,
    };
  },
  methods: {
    onEditLetter(letter) {
      this.letterToEdit = letter;
    },
    closeEditor() {
      this.letterToEdit = null;
    },
  },
};
</script>

<style scoped>

</style>
