<template>
  <div>
    <div
      v-if="!createRemark"
      class="md-layout text-center"
    >
      <div class="md-layout-item">
        <md-button
          class="md-success w-100"
          @click="createRemark = true"
        >
          New Remark
        </md-button>
      </div>
    </div>
    <div
      v-else
      class="md-layout text-center mb-5"
    >
      <div class="md-layout-item md-size-100">
        <form-text
          v-model="form.description"
          name="text"
          rules="required"
          icon="description"
          label="Details"
        />
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-success w-100"
          @click="validate"
        >
          Save
        </md-button>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-danger w-100"
          @click="cancelCreation"
        >
          Cancel
        </md-button>
      </div>
    </div>
    <vue-table
      v-if="vTable.headers"
      ref="vtable"
      :values="vTable.values"
      :headers="vTable.headers"
      :actions="vTable.actions"
      :options="{
        tableClass:'table',
      }"
      editable
      @changed="getRemarks"
      @onDelete="onDelete"
      @onEdit="onEdit"
      @itemChecked="onItemChecked"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import {
  FormText,
} from '@/components/Inputs';

export default {
  components: {
    FormText,
    VueTable,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'created_at',
            mask: 'date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'description',
            mask: 'remark',
            sortable: true,
            width: 50,
          },
          {
            title: 'creator.name',
            mask: 'user',
            sortable: true,
          },
          {
            title: 'show_in_app',
            mask: 'Show in App',
            checkeable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      createRemark: false,
      editingID: null,
      form: {
        description: null,
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getRemarks(params) {
      this.request(this.$API.PROGRAMMES + this.$route.params.id + '/remarks'.paginableUrl(params),
        'get',
        null,
        ({ data }) => {
          this.vTable.values = data;
          this.vTable.values.data = this.vTable.values.data.map((x) => {
            x.editable = true;
            x.onEdit = !x.by_system;
            x.onDelete = !x.by_system;
            return x;
          });
        });
    },

    validate() {
      if (!this.form.description) {
        this.fireError('The description is necessary');
        return;
      }

      if (this.editingID) {
        this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/remarks/${this.editingID}`,
          'put',
          this.form,
          () => {
            this.$refs.vtable.init();
            this.fireSuccess('Remark Updated successfully');
            this.cancelCreation();
          });
      } else {
        this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/remarks`,
          'post',
          this.form,
          () => {
            this.$refs.vtable.init();
            this.fireSuccess('Remark Created successfully');
            this.cancelCreation();
          });
      }
    },
    onDelete(item) {
      this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/remarks/${item.remark_id}`,
        'delete',
        null,
        () => {
          this.$refs.vtable.init();
          this.fireSuccess('Remark Deleted successfully');
        });
    },
    onEdit(item) {
      this.form.description = item.description;
      this.createRemark = true;
      this.editingID = item.remark_id;
    },
    cancelCreation() {
      this.form.description = null;
      this.createRemark = false;
      this.editingID = null;
    },
    onItemChecked(item) {
      this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/remarks/${item.remark_id}/visibility`,
        'put',
        {
          show_in_app: item.show_in_app,
        },
        () => {
          this.fireSuccess('Remark Updated successfully');
        });
    },
  },
};
</script>

<style scoped>
::v-deep .md-table-cell-container {
  white-space: normal;
}
</style>
