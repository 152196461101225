<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Converting Holiday to Attendance
    </template>
    <template slot="body">
      <p>Select which days you want to convert</p>

      <div
        v-for="(h,hi) of holidayDays"
        :key="hi"
      >
        <div class="form-container">
          <input
            :id="`cb-${hi}`"
            v-model="form.days"
            type="checkbox"
            :value="h.date"
            class="mr-2"
          >
          <label :for="`cb-${hi}`">{{ h.label }}</label>
        </div>
      </div>
    </template>
    <template slot="footer">
      <md-button
        class="md-danger"
        @click="$emit('close')"
      >
        Cancel
      </md-button>
      <md-button
        class="md-success"
        @click="confirmConversion"
      >
        Confirm
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    holiday: {
      type: Object,
      default: null,
    },
    weekdays: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    form: {
      days: [],
    },
  }),
  computed: {
    holidayDays() {
      if (this.holiday.days_taken === 1) {
        return [
          {
            date: moment(this.holiday.start_date).format('YYYY-MM-DD'),
            label: moment(this.holiday.start_date).format('dddd DD/MM/YYYY'),
          },
        ];
      }

      const days = [];
      const date = moment(this.holiday.start_date);
      while (date.isSameOrBefore(this.holiday.finish_date)) {
        if (this.weekdays.includes(date.day())) {
          days.push({
            date: date.format('YYYY-MM-DD'),
            label: date.format('dddd DD/MM/YYYY'),
          });
        }
        date.add(1, 'day');
      }

      return days;
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    confirmConversion() {
      const programme_id = this.$route.params.id;
      const { holiday_id } = this.holiday;

      if (!this.form.days.length) {
        this.fireError('Please select at least 1 day');
        return;
      }

      this.requestAsync(`programmes/${programme_id}/holidays/${holiday_id}/to_attendance`, 'put', this.form)
        .then((data) => {
          this.fireSuccess(data);
          this.close();
          this.$emit('onSaved');
        });
    },
  },
};
</script>
<style></style>
