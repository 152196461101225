<template>
  <ValidationObserver ref="form">
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="md-layout-item md-size-95 ml-auto md-small-size-100 d-flex">
          <AgentsSelect
            v-model="form.agent_id"
            :disabled="!editable || !canEditAgent"
            class="flex-grow-1"
            @change="onAgentSelected"
          />
          <CommissionsSelect
            v-if="selectedAgent"
            :key="selectedAgent"
            v-model="form.commission_id"
            :disabled="!editable || !canEditAgent"
            :agent="selectedAgent"
            class="ml-3"
            :no-percentage="!userIsFullAdmin"
            rules="required"
            @change="onCommissionSelected"
          />
          <FormText
            v-model="form.student.mkt_source.title"
            icon="source"
            class="ml-3"
            label="Sales Source"
            disabled
          />
        </div>
        <div class="md-layout-item md-size-95 ml-auto md-small-size-100 d-flex">
          <ProgrammeStatusesSelect
            v-model="form.status"
            class="flex-grow-1"
            rules="required"
            :disabled="!editable"
          />
          <PeriodsSelect
            v-model="form.class_schedule_id"
            :disabled="!editable"
            class="ml-3"
            label="Period"
            @input="onChangePeriod"
          />
        </div>
        <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
          <CoursesSelect
            v-model="form.course_id"
            rules="required"
            :disabled="!editable"
          />
        </div>
        <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
          <md-switch
            v-model="form.no_date"
            :disabled="!editable"
            @change="onChangeNoDate"
          >
            Programme does not have a start date yet
          </md-switch>
          <form-date
            v-if="!form.no_date"
            v-model="form.start_date"
            name="start_date"
            label="Start Date"
            :disabled="!editable"
          />
        </div>
        <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
          <form-date
            v-if="!form.no_date"
            v-model="form.finish_date"
            name="finish_date"
            label="Finish Date"
            disabled
          />
        </div>
        <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
          <form-date
            v-if="!form.no_date"
            v-model="form.tuition_finish_date"
            name="tuition_finish_date"
            label="Tuition Finish Date"
            rules="required"
            disabled
          />
        </div>
        <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
          <form-number
            v-model="form.course_weeks"
            name="course_weeks"
            label="Course Weeks"
            rules="required"
            icon="notes"
            :disabled="!editable"
          />
        </div>
        <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
          <form-number
            v-model="form.holiday_weeks"
            name="holiday_weeks"
            label="Holiday Weeks"
            rules="required"
            icon="notes"
            :disabled="!editable"
          />
        </div>
        <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
          <VisaTypesSelect
            v-model="form.visa_type"
            :disabled="!editable"
            rules="required"
          />
        </div>

        <md-button
          v-if="editable"
          class="md-success float-right"
          @click="validate"
        >
          Save
        </md-button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import moment from 'moment';

import {
  FormDate,
  FormNumber,
  FormText,
} from '@/components/Inputs';
import {
  VisaTypesSelect,
  AgentsSelect,
  ProgrammeStatusesSelect,
  CommissionsSelect,
  CoursesSelect,
  PeriodsSelect,
} from '@/components/Inputs/selects';

extend('required', required);

export default {
  components: {
    FormNumber,
    FormDate,
    VisaTypesSelect,
    AgentsSelect,
    CommissionsSelect,
    ProgrammeStatusesSelect,
    CoursesSelect,
    FormText,
    PeriodsSelect,
  },
  props: {
    programmeModel: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {},
      selectedAgent: null,
    };
  },
  computed: {
    canEditAgent() {
      // Admin
      if (this.user.roles.map((x) => x.role_id).includes(1)) return true;

      // Role with permission
      for (const r of this.user.roles) {
        for (const e of r.endpoints) {
          if (e.endpoint.name === 'programmes.changeAgent') return true;
        }
      }

      // No Permission
      return false;
    },
    agentChanged() {
      return this.programmeModel.agent_id !== this.form.agent_id
      || this.programmeModel.commission_id !== this.form.commission_id;
    },
    startDateChanged() {
      return !moment(this.form.start_date).isSame(this.programmeModel.start_date);
    },
    courseChanged() {
      return this.programmeModel.course_id !== this.form.course_id;
    },
    statusChanged() {
      return this.programmeModel.status !== this.form.status;
    },
  },
  watch: {
    programmeModel: {
      immediate: true,
      handler(val) {
        this.form = { ...val };
      },
    },
  },
  methods: {
    async updateProgramme() {
      try {
        if (this.startDateChanged) {
          await this.confirmHolidaysDeletion();
        }
        if (this.courseChanged) {
          await this.confirmFutureHolidaysRecreation();
        }

        if (!this.form.commission_id) throw new Error('No commission ID');

        if (this.statusChanged) await this.updateStatus();

        if (this.agentChanged) await this.updateAgent();

        await this.updateProg();
      } catch (error) {
        console.error('updateProgramme =======>', error);
      }
    },
    confirmHolidaysDeletion() {
      const text = `If you change the START DATE of this PROGRAMME, all the HOLIDAYS for this PROGRAMME will be 
        deleted and they will be regenerated with the HOLIDAY DAYS created by the Admin. If this PROGRAMME has 
        another HOLIDAYS saved, you will have to create them again.`;

      return this.fireConfirm('Important!', text);
    },
    confirmFutureHolidaysRecreation() {
      const text = `If you change the THE COURSE of this PROGRAMME, FUTURE PROGRAMME HOLIDAYS will be modified
        based on the new programme course days`;

      return this.fireConfirm('Important!', text);
    },
    async updateStatus() {
      return this.requestAsync(`${this.$API.PROGRAMMES + this.$route.params.id}/status`, 'put', {
        status: this.form.status,
        programme_cancel_reason_id: await this.getCancelReasonId(),
      })
        .then((data) => {
          if (data.info) this.fireSuccess(data.info);
        });
    },
    async getCancelReasonId() {
      let programme_cancel_reason_id = null;

      if (this.form.status === 'Cancelled') {
        const { data } = await this.requestAsync('programme_cancel_reasons', 'get', { per_page: 1000 });
        const cancelReasons = data.data;

        const selReason = await this.fireModal({
          title: 'Changing Programme Status to "Cancelled"',
          html: 'Are you sure you want to do this?',
          input: 'select',
          inputPlaceholder: 'Select a Reason',
          inputOptions: cancelReasons.reduce((a, b) => {
            a[b.programme_cancel_reason_id] = b.title;
            return a;
          }, {}),
        });

        if (selReason.value) {
          programme_cancel_reason_id = selReason.value;
        }
      }

      return programme_cancel_reason_id;
    },
    updateAgent() {
      return this.requestAsync(`${this.$API.PROGRAMMES + this.$route.params.id}/agent`, 'put', {
        agent_id: this.form.agent_id,
        commission_id: this.form.commission_id,
      }).then(() => {
        this.fireSuccess('Agent updated succesfully');
      });
    },
    updateProg() {
      return this.requestAsync(this.$API.PROGRAMMES + this.$route.params.id, 'put', { ...this.form })
        .then(() => {
          this.fireSuccess('Programme updated Succesfully!');
          this.$emit('onSave');
        });
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
        if (res) {
          this.updateProgramme();
        }
        return res;
      });
    },
    onChangeNoDate(val) {
      if (val) {
        this.form.start_date = moment('2000/01/01');
      } else {
        this.form.start_date = null;
      }
    },
    onChangePeriod(val) {
      this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/period`, 'put', {
        class_schedule_id: val,
      }, () => {
        this.fireSuccess('Period updated succesfully');
      });
    },
    onAgentSelected(agent) {
      this.selectedAgent = agent;

      if (agent) {
        const commission_ids = agent.commissions.map((x) => x.agent_commission_id);

        if (!this.form.commission_id) {
          [this.form.commission_id] = commission_ids;
        }

        if (!commission_ids.includes(this.form.commission_id)) {
          this.form.commission_id = null;
        }
      } else {
        this.form.commission_id = null;
      }
    },
  },
};
</script>

<style scoped>

</style>
