<template>
  <div>
    <h3>Letter Editor</h3>

    <md-switch v-model="form.show_in_app">
      Show in APP
    </md-switch>

    <LetterPagesEditor :pages="form.pages" />

    <md-button
      class="md-danger float-right"
      @click="goBack"
    >
      Cancel
    </md-button>
    <md-button
      class="md-success float-right"
      :disabled="isLoading"
      @click="saveLetter"
    >
      <div v-if="!isLoading">
        Save Letter
      </div>
      <div v-else>
        Loading
      </div>
    </md-button>
  </div>
</template>

<script>
import LetterPagesEditor from '@/components/LetterPagesEditor.vue';

export default {
  components: {
    LetterPagesEditor,
  },
  props: {
    letter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {},
      isLoading: false,
    };
  },
  mounted() {
    this.form = this.letter;
    this.form.pages = this.letter.pages.sort((a, b) => (a.order > b.order ? 1 : -1));
  },
  methods: {
    saveLetter() {
      this.isLoading = true;
      const URL = `${this.$API.PROGRAMMES + this.$route.params.id}/letters/${this.form.letter_id}`;

      this.request(URL, 'put', {
        ...this.form,
      }, () => {
        this.fireSuccess('Letter Updated successfully!');
        this.goBack();
      },
      () => {
        this.isLoading = false;
      });
    },
    goBack() {
      this.$emit('close');
    },
  },
};
</script>

<style>

</style>
