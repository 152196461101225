var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-20 md-xsmall-100"},[_c('md-card',{staticClass:"md-theme-default md-card-wizard active",staticStyle:{"min-height":"unset"}},[_c('md-card-header',{staticClass:"md-card-header text-center"},[_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.programmeModel.student.first_name)+" "+_vm._s(_vm.programmeModel.student.last_name)+" ")]),_c('h5',{staticClass:"category"},[_vm._v(" "+_vm._s(_vm.programmeModel.student.country.nationality)+" ")])]),_c('md-content',{staticClass:"text-center"},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Programme ID: ")]),_vm._v(" "+_vm._s(_vm.programmeModel.programme_id)+" ")]),_c('h4',{staticClass:"mt-0"},[_c('b',[_vm._v(_vm._s(_vm.studentCourse))])]),_c('p',[_vm._v(" Registration Number "),_c('br'),_c('span',{staticClass:"reglabel"},[_vm._v(_vm._s(_vm.programmeModel.student.student_number))])])])],1),(_vm.programmeModel.student_id)?_c('md-button',{staticClass:"md-success w-100",attrs:{"to":{ name: 'StudentResume', params: { id: _vm.programmeModel.student_id } }}},[_c('span',{staticClass:"md-label"},[_c('md-icon',{staticClass:"material-icons mr-2"},[_vm._v("person")])],1),_vm._v(" Go Student Profile ")]):_vm._e(),(!_vm.editable)?_c('md-button',{staticClass:"md-warning w-100",on:{"click":function($event){_vm.editable = true}}},[_c('span',{staticClass:"md-label"},[_c('md-icon',{staticClass:"material-icons mr-2"},[_vm._v("edit")])],1),_vm._v(" Edit Programme ")]):_vm._e()],1),_c('div',{staticClass:"md-layout-item md-size-80 md-xsmall-size-100"},[(!_vm.loading)?_c('tabs',{attrs:{"tab-name":[
        'Programme',
        'Enrolments',
        'Holidays',
        'Sick Leave',
        'Attendance',
        'Letters',
        'Assessments',
        'Remarks'
      ],"color-button":"success"},on:{"change":_vm.onChange}},[_c('programme-details',{attrs:{"slot":"tab-pane-1","programme-model":_vm.programmeModel,"editable":_vm.editable},on:{"onSave":_vm.onSave},slot:"tab-pane-1"}),_c('programme-enrolments',{attrs:{"slot":"tab-pane-2","programme-model":_vm.programmeModel},on:{"onSave":_vm.onSave},slot:"tab-pane-2"}),_c('programme-holidays',{attrs:{"slot":"tab-pane-3","holiday-weeks":_vm.programmeModel.holiday_weeks,"programme-model":_vm.programmeModel},on:{"onSave":_vm.onSave},slot:"tab-pane-3"}),_c('programme-sick-leave',{attrs:{"slot":"tab-pane-4","holiday-weeks":_vm.programmeModel.holiday_weeks,"programme-model":_vm.programmeModel},on:{"onSave":_vm.onSave},slot:"tab-pane-4"}),_c('programme-attendance',{attrs:{"slot":"tab-pane-5","programme-model":_vm.programmeModel,"student-id":_vm.programmeModel.student_id,"editable":_vm.editable},slot:"tab-pane-5"}),_c('programme-letters',{attrs:{"slot":"tab-pane-6","programme-model":_vm.programmeModel},slot:"tab-pane-6"}),_c('programme-assessments',{attrs:{"slot":"tab-pane-7"},slot:"tab-pane-7"}),_c('programme-remarks',{attrs:{"slot":"tab-pane-8"},slot:"tab-pane-8"})],1):_c('Loader')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }