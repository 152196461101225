<template>
  <div>
    <div
      v-if="!createHoliday"
      class="md-layout text-center"
    >
      <div class="md-layout-item">
        <md-button
          class="md-success w-100"
          @click="createHoliday = true"
        >
          New Holidays
        </md-button>
      </div>
    </div>
    <div
      v-else
      class="md-layout text-center mb-5"
    >
      <div class="md-layout-item md-size-33">
        <form-date
          v-model="form.start_date"
          name="start_date"
          label="Start Date"
          rules="required"
        />
      </div>
      <div class="md-layout-item md-size-33">
        <form-date
          v-model="form.finish_date"
          name="finish_date"
          label="Finish Date"
          rules="required"
        />
      </div>
      <div class="md-layout-item md-size-33">
        <form-text
          v-model="form.remark"
          label="Remark"
          name="remark"
          icon="subject"
        />
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-success w-100"
          @click="validate"
        >
          Save
        </md-button>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-danger w-100"
          @click="cancelCreation"
        >
          Cancel
        </md-button>
      </div>
    </div>
    <div class="md-layout text-center my-5">
      <div class="md-layout-item md-size-33">
        <b>Total:</b> {{ holiday_status.total }} days
      </div>
      <div class="md-layout-item md-size-33">
        <b>Taken:</b> {{ holiday_status.taken }} days
      </div>
      <div class="md-layout-item md-size-33">
        <b>Available:</b> {{ holiday_status.available }} days
      </div>
    </div>
    <vue-table
      v-if="vTable.headers"
      ref="vtable"
      :values="vTable.values"
      :headers="vTable.headers"
      :actions="vTable.actions"
      :options="{
        tableClass:'table'
      }"
      @changed="getHolidays"
      @onEdit="onEdit"
      @onDelete="onDelete"
      @onConvertToAttendance="onConvertToAttendance"
    />

    <ConversionModal
      v-if="showConversionModal"
      :holiday="holidayToConvert"
      :weekdays="programmeModel.course.weekdays"
      @close="closeConversionModal"
      @onSaved="init"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import {
  FormDate,
  FormText,
} from '@/components/Inputs';
import ConversionModal from './ProgrammeHolidaysConverterModal.vue';

const moment = require('moment');

export default {
  components: {
    VueTable,
    FormDate,
    FormText,
    ConversionModal,
  },
  props: {
    programmeModel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'start_date',
            mask: 'start date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'finish_date',
            mask: 'finish date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'days_taken',
            mask: 'days taken',
            sortable: true,
          },
          {
            title: 'remark',
            sortable: true,
          },
        ],
        values: {},
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Convert into Attendance',
            callback: 'onConvertToAttendance',
            icon: 'refresh',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
      },
      form: {
        start_date: null,
        finish_date: null,
        remark: null,
        programme_id: this.$route.params.id,
      },
      holiday_status: {
        total: 0,
        taken: 0,
        available: 0,
      },
      createHoliday: false,
      editingId: null,
      showConversionModal: false,
      holidayToConvert: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$refs.vtable.init();
      this.getHolidayStatus();
    },
    getHolidays(params) {
      this.request(this.$API.PROGRAMMES + this.$route.params.id + '/holidays'.paginableUrl(params),
        'get',
        null,
        ({ data }) => {
          this.vTable.values = data;
          // Showing buttons only when is posible to make an action
          this.vTable.values.data = this.vTable.values.data.map((x) => {
            const aux = x;
            aux.onDelete = moment(x.finish_date).isAfter(moment());
            return aux;
          });
        });
    },
    getHolidayStatus() {
      this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/holidays_status`,
        'get',
        null,
        ({ data }) => {
          this.holiday_status = data;
        });
    },
    onConvertToAttendance(holiday) {
      this.showConversionModal = true;
      this.holidayToConvert = holiday;
    },
    closeConversionModal() {
      this.showConversionModal = false;
      this.holidayToConvert = null;
    },
    validate() {
      let valid = true;

      if (!this.form.start_date) {
        this.fireError('Select a start date');
        valid = false;
      }

      if (!this.form.finish_date) {
        this.fireError('Select a finish date');
        valid = false;
      }

      if (valid) {
        if (this.editingId) {
          if (moment(this.form.finish_date).isBefore(moment().startOf('day'))) {
            this.fireError('New finish date can not be before current date.');
          } else {
            this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/holidays/${this.editingId}`,
              'put',
              this.form,
              () => {
                this.fireSuccess('Holiday updated successfully');
                this.cancelCreation();
                this.init();
                this.$emit('onSave');
              });
          }
        } else {
          this.fireConfirm(
            'Creating Holiday',
            'In case your holiday is overlapping with a previous Bank Holiday, would you like to delete the previous and create the new one?',
            'No',
          )
            .then(() => {
              this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/holidays`,
                'post',
                {
                  ...this.form,
                  force: true, // When pressing yes, use force to delete previous holidays
                },
                () => {
                  this.fireSuccess('Holidays created successfully');
                  this.cancelCreation();
                  this.$emit('onSave');
                  this.init();
                });
            })
            .catch(() => {
              this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/holidays`,
                'post',
                this.form,
                () => {
                  this.fireSuccess('Holidays created successfully');
                  this.cancelCreation();
                  this.$emit('onSave');
                  this.init();
                });
            });
        }
      }
    },
    cancelCreation() {
      this.form = {
        start_date: null,
        finish_date: null,
        remark: null,
        programme_id: this.$route.params.id,
      };
      this.createHoliday = false;
      this.editingId = null;
    },
    onEdit(item) {
      this.editingId = item.holiday_id;
      this.createHoliday = true;
      this.form.start_date = item.start_date;
      this.form.finish_date = item.finish_date;
      this.form.remark = item.remark;
    },
    onDelete(item) {
      this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/holidays/${item.holiday_id}`,
        'delete',
        null,
        () => {
          this.fireSuccess('Holiday deleted successfully');
          this.$emit('onSave');
          this.init();
        });
    },
  },
};
</script>

<style scoped>

</style>
