<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-20 md-xsmall-100">
      <md-card
        class="md-theme-default md-card-wizard active"
        style="min-height: unset;"
      >
        <md-card-header class="md-card-header text-center">
          <h3 class="title">
            {{ programmeModel.student.first_name }} {{ programmeModel.student.last_name }}
          </h3>
          <h5 class="category">
            {{ programmeModel.student.country.nationality }}
          </h5>
        </md-card-header>
        <md-content class="text-center">
          <p class="mb-0">
            <b>Programme ID: </b> {{ programmeModel.programme_id }}
          </p>
          <h4 class="mt-0">
            <b>{{ studentCourse }}</b>
          </h4>
          <p>
            Registration Number <br>
            <span class="reglabel">{{ programmeModel.student.student_number }}</span>
          </p>
        </md-content>
      </md-card>

      <md-button
        v-if="programmeModel.student_id"
        class="md-success w-100"
        :to="{ name: 'StudentResume', params: { id: programmeModel.student_id } }"
      >
        <span class="md-label">
          <md-icon class="material-icons mr-2">person</md-icon>
        </span>
        Go Student Profile
      </md-button>

      <md-button
        v-if="!editable"
        class="md-warning w-100"
        @click="editable = true"
      >
        <span class="md-label">
          <md-icon class="material-icons mr-2">edit</md-icon>
        </span>
        Edit Programme
      </md-button>
    </div>
    <div class="md-layout-item md-size-80 md-xsmall-size-100">
      <tabs
        v-if="!loading"
        :tab-name="[
          'Programme',
          'Enrolments',
          'Holidays',
          'Sick Leave',
          'Attendance',
          'Letters',
          'Assessments',
          'Remarks'
        ]"
        color-button="success"
        @change="onChange"
      >
        <programme-details
          slot="tab-pane-1"
          :programme-model="programmeModel"
          :editable="editable"
          @onSave="onSave"
        />
        <programme-enrolments
          slot="tab-pane-2"
          :programme-model="programmeModel"
          @onSave="onSave"
        />
        <programme-holidays
          slot="tab-pane-3"
          :holiday-weeks="programmeModel.holiday_weeks"
          :programme-model="programmeModel"
          @onSave="onSave"
        />
        <programme-sick-leave
          slot="tab-pane-4"
          :holiday-weeks="programmeModel.holiday_weeks"
          :programme-model="programmeModel"
          @onSave="onSave"
        />
        <programme-attendance
          slot="tab-pane-5"
          :programme-model="programmeModel"
          :student-id="programmeModel.student_id"
          :editable="editable"
        />
        <programme-letters
          slot="tab-pane-6"
          :programme-model="programmeModel"
        />
        <programme-assessments slot="tab-pane-7" />
        <programme-remarks slot="tab-pane-8" />
      </tabs>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import { Tabs } from '@/components';
import Loader from '@/components/loader.vue';
import ProgrammeEnrolments from './ProgrammeEnrolments.vue';
import ProgrammeDetails from './ProgrammeDetails.vue';
import ProgrammeHolidays from './ProgrammeHolidays.vue';
import ProgrammeAttendance from './ProgrammeAttendance.vue';
import ProgrammeLetters from './ProgrammeLetters.vue';
import ProgrammeRemarks from './ProgrammeRemarks.vue';
import ProgrammeAssessments from './ProgrammeAssessments.vue';
import ProgrammeSickLeave from './ProgrammeSickLeave.vue';

export default {
  components: {
    Tabs,
    ProgrammeEnrolments,
    ProgrammeDetails,
    ProgrammeHolidays,
    ProgrammeAttendance,
    ProgrammeLetters,
    ProgrammeAssessments,
    ProgrammeRemarks,
    ProgrammeSickLeave,
    Loader,
  },
  data() {
    return {
      programmeModel: {
        programme_id: null,
        student_id: null,
        course_id: null,
        package_id: null,
        agent_id: null,
        start_date: null,
        finish_date: null,
        course_weeks: null,
        holiday_weeks: null,
        status: null,
        class_schedule_id: null,
        visa_type: null,
        created_by: null,
        updated_by: null,
        created_at: null,
        updated_at: null,
        student: {
          student_id: null,
          student_number: null,
          first_name: null,
          last_name: null,
          email: null,
          phone: null,
          genre: null,
          date_of_birth: null,
          mkt_source_id: null,
          country_id: null,
          created_by: null,
          updated_by: null,
          created_at: null,
          updated_at: null,
          country: {},
        },
      },
      editable: false,
      loading: true,
    };
  },
  computed: {
    studentCourse() {
      if (!this.programmeModel) return null;
      if (!this.programmeModel.course) return null;
      return this.programmeModel.course.title;
    },
  },
  mounted() {
    this.getProgramme(this.$route.params.id);
  },
  methods: {
    getProgramme(id) {
      this.request(`${this.$API.PROGRAMMES}${id}`, 'get', null, ({ data }) => {
        this.programmeModel = data;
      },
      () => {
        this.loading = false;
      });
    },
    onSave() {
      this.getProgramme(this.$route.params.id);
      this.editable = false;
    },
    onChange() {
      this.editable = false;
    },
  },
};
</script>

<style scoped>
  .reglabel {
    padding: 5px 10px;
    background: #ccc;
    border-radius: 1em;
    margin-left: 10px;
    color: #fff;
    font-weight: bold;
  }
  ::v-deep div[class^="tab-pane"] {
    width: 100%;
  }
</style>
