<template>
  <div>
    <div
      v-if="!createLetter"
      class="md-layout text-center"
    >
      <div class="md-layout-item">
        <md-button
          class="md-success md-sm w-100"
          @click="createLetter = true"
        >
          New Letter
        </md-button>
      </div>
    </div>
    <div
      v-else
      class="md-layout text-center mb-5"
    >
      <div class="md-layout-item md-size-50">
        <LetterTemplatesSelect
          v-model="form.letter_template_id"
          rules="required"
          @change="onLetterTemplateChange"
        />
      </div>
      <div class="md-layout-item md-size-50">
        <AuthoritiesSelect
          v-model="form.authority_id"
          rules="required"
        />
      </div>
      <div class="md-layout-item md-size-100">
        <md-switch
          v-if="hasApp"
          v-model="form.show_in_app"
        >
          Show in APP
        </md-switch>
        <md-switch v-model="form.show_sign">
          Show Signature
        </md-switch>
        <md-switch
          v-if="userCanDeactivateLettersLink"
          v-model="form.show_link"
        >
          Show QR Code
        </md-switch>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-success md-sm w-100"
          :disabled="isLoading"
          @click="validate"
        >
          <div v-if="!isLoading">
            Save
          </div>
          <div v-else>
            Loading
          </div>
        </md-button>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-danger md-sm w-100"
          @click="cancelCreation"
        >
          Cancel
        </md-button>
      </div>
    </div>

    <vue-table
      v-if="vTable.headers"
      ref="vtable"
      :values="vTable.values"
      :headers="vTable.headers"
      :actions="vTable.actions"
      :options="{
        tableClass:'table',
      }"
      @onEdit="onEdit"
      @onDelete="onDelete"
      @changed="getLetters"
      @onPreview="onPreview"
      @onSendEmail="onSendEmail"
      @onSetShow="onSetShow"
      @onUnsetShow="onUnsetShow"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import {
  LetterTemplatesSelect,
  AuthoritiesSelect,
} from '@/components/Inputs/selects';

export default {
  components: {
    VueTable,
    LetterTemplatesSelect,
    AuthoritiesSelect,
  },
  props: {
    programmeModel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      vTable: {
        headers: [
          {
            title: 'letter_id',
            mask: 'ID',
            sortable: true,
          },
          {
            title: 'created_at',
            mask: 'issue date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'letter_template.title',
            mask: 'type',
            sortable: true,
          },
          {
            title: 'show_in_app',
            mask: 'shown in app',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
        ],
        actions: [
          {
            buttonClass: 'md-success',
            tooltip: 'Show in App',
            callback: 'onSetShow',
            icon: 'cloud_queue',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Hide in App',
            callback: 'onUnsetShow',
            icon: 'cloud_off',
          },
          {
            buttonClass: 'md-info',
            callback: 'onPreview',
            tooltip: 'Preview',
            icon: 'visibility',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Send Email',
            callback: 'onSendEmail',
            icon: 'mail',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      createLetter: false,
      form: {
        letter_template_id: null,
        authority_id: null,
        programme_id: this.$route.params.id,
        show_hf: false,
        show_sign: false,
        show_in_app: false,
        show_stamp: false,
        show_link: true,
      },
      templates: [],
      authorities: [],
    };
  },
  computed: {
    hasApp() {
      return !!this.programmeModel?.student.app_registration_date;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getLetters(params) {
      this.request(this.$API.PROGRAMMES + this.$route.params.id + '/letters'.paginableUrl(params),
        'get',
        null,
        ({ data }) => {
          this.vTable.values = data;
          this.vTable.values.data = this.vTable.values.data.map((x) => {
            x.onSetShow = this.hasApp && x.show_in_app === false;
            x.onUnsetShow = this.hasApp && x.show_in_app === true;
            return x;
          });
        });
    },
    onLetterTemplateChange(template) {
      this.form.show_stamp = template.show_stamp;
    },

    async validate() {
      let valid = true;
      this.isLoading = true;
      if (!this.form.authority_id) {
        this.fireError('Select a Signing Authority');
        valid = false;
        this.isLoading = false;
      }
      if (!this.form.letter_template_id) {
        this.fireError('Select a Template');
        valid = false;
        this.isLoading = false;
      }

      if (valid) {
        let data = {};

        if (this.form.show_in_app) {
          data = await this.fireModal({
            title: 'Letter creation',
            html: 'You are sharing this letter in the app. Want to modify the push notification message?',
            input: 'text',
            inputPlaceholder: 'Push Notification Message',
          });
        }

        this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/letters`,
          'post',
          {
            ...this.form,
            message: data.value,
          },
          () => {
            this.fireSuccess('Letter Created Succesfully');
            this.$refs.vtable.init();
            this.cancelCreation();
          },
          () => {
            this.isLoading = false;
          });
      }
    },
    cancelCreation() {
      this.form = {
        letter_template_id: null,
        authority_id: null,
        programme_id: this.$route.params.id,
        show_hf: false,
        show_sign: false,
        show_link: true,
      };
      this.createLetter = false;
    },
    onDelete(item) {
      this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/letters/${item.letter_id}`,
        'delete',
        null,
        () => {
          this.fireSuccess('Letter deleted successfully');
          this.$refs.vtable.init();
        });
    },
    onPreview(item) {
      if (item.pdf) {
        this.getLetterUrl(item.pdf);
      }
    },
    onEdit(item) {
      this.$emit('onEditLetter', item);
    },
    onSendEmail(item) {
      this.fireConfirm(
        'You are about to send an email',
        'If the letter is not shared in the app. It will be shared automatically. Are you sure?',
      ).then(() => {
        this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/letters/${item.letter_id}`,
          'post',
          null,
          (res) => {
            this.fireSuccess(res);
            this.$refs.vtable.init();
          });
      });
    },
    async onSetShow(item) {
      const data = await this.fireModal({
        title: 'Sharing Letter',
        html: 'You are sharing this letter in the app. Want to modify the push notification message?',
        input: 'text',
        inputPlaceholder: 'Push Notification Message',
      });

      this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/letters/${item.letter_id}`, 'put', {
        pages: item.pages,
        show_in_app: true,
        message: data.value,
      }, () => {
        this.fireSuccess('Letter will be shown in APP from now on.');
        this.$refs.vtable.init();
      });
    },
    onUnsetShow(item) {
      this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/letters/${item.letter_id}`, 'put', {
        pages: item.pages,
        show_in_app: false,
      }, () => {
        this.fireSuccess('Letter will be hidden in APP from now on.');
        this.$refs.vtable.init();
      });
    },
  },
};
</script>

<style scoped>

</style>
