<template>
  <div>
    <div
      v-if="!createSickDay"
      class="md-layout text-center"
    >
      <div class="md-layout-item">
        <md-button
          class="md-success w-100"
          @click="startCreation"
        >
          New Sick Leave day
        </md-button>
      </div>
    </div>
    <div
      v-else
      class="md-layout text-center mb-5"
    >
      <div class="md-layout-item md-size-33">
        <form-date
          v-model="form.start_date"
          name="start_date"
          label="Start Date"
          rules="required"
        />
      </div>
      <div class="md-layout-item md-size-33">
        <form-date
          v-model="form.finish_date"
          name="finish_date"
          label="Finish Date"
          rules="required"
        />
      </div>
      <div class="md-layout-item md-size-33">
        <form-text
          v-model="form.remark"
          label="Remark"
          name="remark"
          icon="subject"
        />
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-success w-100"
          @click="validate"
        >
          Save
        </md-button>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-danger w-100"
          @click="cancelCreation"
        >
          Cancel
        </md-button>
      </div>
    </div>

    <div class="md-layout mt-4">
      <div class="md-layout-item">
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          @changed="getSickDays"
          @onEdit="onEdit"
          @onDelete="onDelete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import {
  FormDate,
  FormText,
} from '@/components/Inputs';

export default {
  components: {
    VueTable,
    FormDate,
    FormText,
  },
  props: {
    programmeModel: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'start_date',
          mask: 'start date',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'finish_date',
          mask: 'finish date',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'remark',
          sortable: true,
        },
      ],
      values: {},
      actions: [
        {
          buttonClass: 'md-warning',
          tooltip: 'Edit',
          callback: 'onEdit',
          icon: 'edit',
        },
        {
          buttonClass: 'md-danger',
          tooltip: 'Delete',
          callback: 'onDelete',
          icon: 'close',
        },
      ],
    },
    form: {
      start_date: null,
      finish_date: null,
      remark: null,
      programme_id: null,
    },
    createSickDay: false,
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$refs.vtable.init();
    },
    startCreation() {
      this.createSickDay = true;
    },
    cancelCreation() {
      this.createSickDay = false;
    },
    getSickDays(params) {
      this.request(this.$API.PROGRAMMES + this.$route.params.id + '/sick_days'.paginableUrl(params),
        'get',
        null,
        ({ data }) => {
          this.vTable.values = data;
        });
    },
    validate() {
      let valid = true;

      if (!this.form.start_date) {
        this.fireError('Select a start date');
        valid = false;
      }

      if (!this.form.finish_date) {
        this.fireError('Select a finish date');
        valid = false;
      }

      if (valid) {
        if (this.editingId) {
          this.fireConfirm(
            'Updating Sick leave',
            'All the dates that are not included anymore will be set as absent in the attendance of the student. Are you sure?',
          )
            .then(() => {
              this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/sick_days/${this.editingId}`,
                'put',
                this.form,
                () => {
                  this.fireSuccess('Sick day updated successfully');
                  this.cancelCreation();
                  this.init();
                  this.$emit('onSave');
                });
            });
        } else {
          this.fireConfirm(
            'Creating Sick leave',
            'All the dates that are included will be set as present in the attendance of the student. Are you sure?',
          )
            .then(() => {
              this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/sick_days`,
                'post',
                this.form,
                () => {
                  this.fireSuccess('Sick day created successfully');
                  this.cancelCreation();
                  this.$emit('onSave');
                  this.init();
                });
            });
        }
      }
    },
    onEdit(item) {
      this.editingId = item.sick_day_id;
      this.createSickDay = true;
      this.form.start_date = item.start_date;
      this.form.finish_date = item.finish_date;
      this.form.remark = item.remark;
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Sick leave',
        'All the dates that are not included anymore will be set as absent in the attendance of the student. Are you sure?',
      )
        .then(() => {
          this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/sick_days/${item.sick_day_id}`,
            'delete',
            null,
            () => {
              this.fireSuccess('Sick day deleted successfully');
              this.$emit('onSave');
              this.init();
            });
        });
    },
  },
};
</script>
