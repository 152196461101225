<template>
  <div>
    <div
      v-if="form.isEditing"
      class="md-layout text-center mb-5"
    >
      <div class="md-layout-item md-size-25">
        <form-number
          v-model="form.grade"
          label="Exam Grade"
          icon="notes"
        />
      </div>
      <div class="md-layout-item md-size-25">
        <form-number
          v-model="form.participative_grade"
          label="Participative Grade"
          icon="notes"
        />
      </div>
      <div class="md-layout-item md-size-25">
        <form-number
          v-model="form.productive_grade"
          label="Productive Grade"
          icon="notes"
        />
      </div>
      <div class="md-layout-item md-size-25">
        <form-number
          v-model="form.receptive_grade"
          label="Receptive Grade"
          icon="notes"
        />
      </div>
      <div class="md-layout-item md-size-100">
        <form-text
          v-model="form.remark"
          icon="spellcheck"
          label="Remark"
        />
      </div>

      <div class="md-layout-item md-size-100">
        <md-button
          class="md-success w-100"
          @click="validate"
        >
          Save
        </md-button>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-danger w-100"
          @click="cancelEdit"
        >
          Cancel
        </md-button>
      </div>
    </div>

    <vue-table
      v-if="vTable.headers"
      ref="vtable"
      :values="vTable.values"
      :headers="vTable.headers"
      :actions="vTable.actions"
      :options="{
        tableClass:'table',
      }"
      @changed="getAssessments"
      @onEdit="onEdit"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import {
  FormText,
  FormNumber,
} from '@/components/Inputs';

export default {
  components: {
    VueTable,
    FormText,
    FormNumber,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'assessment.title',
            mask: 'title',
            sortable: true,
          },
          {
            title: 'assessment.description',
            mask: 'description',
            sortable: true,
          },
          {
            title: 'assessment.date',
            mask: 'assessment date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'grade',
            mask: 'exam grade',
            sortable: true,
            after: '%',
          },
          {
            title: 'participative_grade',
            mask: 'participative grade',
            sortable: true,
          },
          {
            title: 'receptive_grade',
            mask: 'receptive grade',
            sortable: true,
          },
          {
            title: 'productive_grade',
            mask: 'productive grade',
            sortable: true,
          },
          {
            title: 'final_grade',
            mask: 'final grade',
            after: '%',
            sortable: true,
          },
          {
            title: 'remark',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit',
            callback: 'onEdit',
            icon: 'edit',
          },
        ],
        values: {},
      },
      form: {
        isEditing: false,
        assessment_id: null,
        grade: null,
        participative_grade: null,
        productive_grade: null,
        receptive_grade: null,
        remark: null,
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getAssessments(params) {
      this.request(`${this.$API.PROGRAMMES}${this.$route.params.id}/${this.$API.ASSESSMENTS.paginableUrl(params)}`,
        'get',
        null,
        ({ data }) => {
          this.vTable.values = data;
        });
    },
    onEdit(item) {
      this.form = {
        isEditing: true,
        assessment_enrolment_id: item.assessment_enrolment_id,
        grade: item.grade,
        participative_grade: item.participative_grade,
        productive_grade: item.productive_grade,
        receptive_grade: item.receptive_grade,
        remark: item.remark,
      };
    },
    validate() {
      this.request(`assessments/${this.form.assessment_id}/entries/${this.form.assessment_enrolment_id}`, 'put', this.form, () => {
        this.fireSuccess('Assessment updated succesfully');
        this.cancelEdit();

        this.$refs.vtable.init();
      });
    },
    cancelEdit() {
      this.form = {
        isEditing: false,
        assessment_id: null,
        remark: null,
      };
    },
  },
};
</script>

<style scoped>
>>> .md-table-cell-container {
  white-space: normal;
}
</style>
