<template>
  <div>
    <div
      v-if="!createEnrolment && !editEnrolment"
      class="md-layout text-center"
    >
      <div class="md-layout-item">
        <md-button
          class="md-success w-100"
          @click="createEnrolment = true"
        >
          New Enrolment
        </md-button>
      </div>
    </div>
    <div
      v-else-if="!editEnrolment"
      class="md-layout text-center mb-5"
    >
      <div class="md-layout-item md-size-50">
        <ClassroomsSelect
          v-model="form.classroom_id"
          class="text-left"
          :parameters="{
            class_schedule_id: programmeModel.class_schedule_id,
            course_id: programmeModel.course_id,
            force: true,
          }"
        >
          <template v-slot:error>
            There is no Classrooms for this Period and Course
          </template>
        </ClassroomsSelect>
      </div>
      <div class="md-layout-item md-size-50">
        <form-date
          v-model="form.start_date"
          name="date"
          label="Start Date"
          rules="required"
        />
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-success w-100"
          @click="validate"
        >
          Save
        </md-button>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-danger w-100"
          @click="cancelCreation"
        >
          Cancel
        </md-button>
      </div>
    </div>

    <div
      v-if="editEnrolment"
      class="md-layout text-center mb-5"
    >
      <div class="md-layout-item">
        <ClassroomsSelect
          v-model="editForm.classroom_id"
          class="text-left"
          :parameters="{
            class_schedule_id: programmeModel.class_schedule_id,
            course_id: programmeModel.course_id,
            force: true,
          }"
        >
          <template v-slot:error>
            There is no Classrooms for this Period and Course
          </template>
        </ClassroomsSelect>
      </div>
      <div class="md-layout-item">
        <form-date
          v-model="editForm.start_date"
          name="date"
          label="Start Date"
          rules="required"
        />
      </div>
      <div class="md-layout-item">
        <form-date
          v-model="editForm.finish_date"
          name="finish_date"
          label="Finish Date"
        />
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-success w-100"
          @click="updateEnrolment"
        >
          Save
        </md-button>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-danger w-100"
          @click="cancelCreation"
        >
          Cancel
        </md-button>
      </div>
    </div>

    <vue-table
      v-if="vTable.headers"
      ref="vtable"
      :values="vTable.values"
      :headers="vTable.headers"
      :actions="vTable.actions"
      :options="{
        tableClass:'table'
      }"
      @changed="getEnrolments"
      @onEdit="onEdit"
      @onSendMail="onSendMail"
      @onDelete="deleteEnrolment"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import moment from 'moment';
import {
  FormDate,
} from '@/components/Inputs';
import {
  ClassroomsSelect,
} from '@/components/Inputs/selects';

export default {
  components: {
    ClassroomsSelect,
    FormDate,
    VueTable,
  },
  props: {
    programmeModel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'classroom.classroom_code',
            mask: 'classroom code',
            sortable: true,
          },
          {
            title: 'classroom.classroom_name.name',
            mask: 'classroom name',
            sortable: true,
          },
          {
            title: 'start_date',
            mask: 'start date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'finish_date',
            mask: 'finish date',
            sortable: true,
            dateFormat: true,
          },
        ],
        values: {},
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Send Mail',
            callback: 'onSendMail',
            icon: 'mail',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
      },
      createEnrolment: false,
      editEnrolment: false,
      form: {
        classroom: null,
        classroom_id: null,
        start_date: null,
        programme_id: this.$route.params.id,
      },
      editForm: {
        classroom: null,
        enrolment_id: null,
        classroom_id: null,
        start_date: null,
        finish_date: null,
        programme_id: this.$route.params.id,
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.classroom': function (val) {
      if (val) this.form.classroom_id = val.classroom_id;
    },
    // eslint-disable-next-line func-names
    'editForm.classroom': function (val) {
      if (val) this.editForm.classroom_id = val.classroom_id;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getEnrolments(params) {
      this.request(this.$API.PROGRAMMES + this.$route.params.id + '/enrolments'.paginableUrl(params),
        'get',
        null,
        ({ data }) => {
          const aux = data;

          // Activate sendMail button only for the last enrolment
          let last = null;

          aux.data.forEach((x) => {
            if (!last || moment(last.start_date).format('YYYY-MM-DD') < moment(x.start_date).format('YYYY-MM-DD')) {
              last = x;
            }
          });

          if (last) {
            aux.data = aux.data.map((x) => {
              const a = x;
              a.onSendMail = x.enrolment_id === last.enrolment_id;
              return a;
            });
          }

          this.vTable.values = aux;
        });
    },
    validate() {
      let valid = true;

      if (!this.form.classroom_id) {
        this.fireError('Select a Classroom');
        valid = false;
      }

      if (!this.form.start_date) {
        this.fireError('Select a Start Date');
        valid = false;
      }

      if (valid) {
        this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/enrolments`,
          'post', this.form, (data) => {
            this.fireSuccess('Enrolment created succesfully');

            if (data.enrolments.length > data.limit) {
              this.fireWarning(`
                For the selected Starting Date the classroom is over its limit. 
                (${data.enrolments.length} of ${data.limit} enrolments)
              `);
            }
            this.$refs.vtable.init();
            this.$emit('onSave');
            this.createEnrolment = false;
          });
      }
    },
    cancelCreation() {
      this.form = {
        classroom: null,
        classroom_id: null,
        start_date: null,
        programme_id: this.$route.params.id,
      };
      this.editForm = {
        classroom: null,
        enrolment_id: null,
        classroom_id: null,
        start_date: null,
        finish_date: null,
        programme_id: this.$route.params.id,
      };
      this.createEnrolment = false;
      this.editEnrolment = false;
    },
    onEdit(item) {
      const aux = { ...item };
      aux.classroom.name = `${aux.classroom.classroom_code} | ${aux.classroom.classroom_name.name}`;

      this.editEnrolment = true;
      this.editForm = aux;
    },
    deleteEnrolment(item) {
      this.request(`${this.$API.PROGRAMMES}${item.programme_id}/enrolments/${item.enrolment_id}`,
        'delete',
        null,
        (res) => {
          this.fireSuccess(res);
          this.$emit('onSave');
          this.$refs.vtable.init();
        });
    },
    onSendMail(item) {
      this.fireConfirm('You are about to send an email', 'Are you sure?').then(() => {
        this.request(`${this.$API.PROGRAMMES}${item.programme_id}/enrolments/${item.enrolment_id}`,
          'post',
          null,
          (res) => {
            this.fireSuccess(res);
          });
      });
    },
    updateEnrolment() {
      let valid = true;

      if (!this.editForm.classroom_id) {
        this.fireError('Select a Classroom');
        valid = false;
      }

      if (!this.editForm.start_date) {
        this.fireError('Select a Start Date');
        valid = false;
      }

      if (valid) {
        const form = this.editForm;

        this.request(`${this.$API.PROGRAMMES}${form.programme_id}/enrolments/${form.enrolment_id}`,
          'put',
          form,
          () => {
            this.fireSuccess('Enrolment updated succesfully!');
            this.$emit('onSave');
            this.$refs.vtable.init();
            this.editEnrolment = false;
            this.editForm = {
              enrolment_id: null,
              classroom_id: null,
              start_date: null,
              finish_date: null,
              programme_id: this.$route.params.id,
            };
          });
      }
    },
  },
};
</script>

<style scoped>

</style>
