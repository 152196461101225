<template>
  <div>
    <div
      v-if="course_progress"
      class="md-layout"
    >
      <div class="md-layout-item md-size-50">
        <p><b>Attendance Rate:</b> {{ course_progress.attendance_rate }}%</p>
        <p><b>Max. Possible Attendance Rate:</b> {{ course_progress.max_attendance_rate }}%</p>
        <p><b>Total Days:</b> {{ course_progress.total_course_days }}</p>
        <p><b>Performed:</b> {{ course_progress.performed_days }}</p>
        <p><b>Present:</b> {{ course_progress.attended_days }}</p>
        <p><b>Absent:</b> {{ course_progress.absent_days }}</p>
      </div>
      <div class="md-layout-item md-size-50">
        <label>Attendance Rate ({{ course_progress.attendance_rate }}%)</label>
        <md-progress-bar
          :class="course_progress.attendance_rate >85 ? 'md-primary' : 'md-danger'"
          :md-value="parseFloat(course_progress.attendance_rate)"
        />

        <label>Progress ({{ courseProgress }}%)</label>
        <md-progress-bar
          class="md-success"
          :md-value="courseProgress"
        />
      </div>
    </div>

    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-button
          v-if="!editable"
          class="md-success md-sm pull-right"
          @click="editable = true"
        >
          Edit Attendances
          <span class="md-label md-label-right">
            <md-icon class="material-icons">edit</md-icon>
          </span>
        </md-button>
        <md-button
          v-else
          class="md-danger md-sm pull-right"
          @click="editable = false"
        >
          Cancel Edition
          <span class="md-label md-label-right">
            <md-icon class="material-icons">edit</md-icon>
          </span>
        </md-button>
      </div>
      <div class="md-layout-item">
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          :editable="editable"
          extra-param-validation="inactive"
          @changed="getAttendance"
          @onCheckLogs="onCheckLogs"
          @itemChecked="onItemChecked"
          @onGoTo="onGoTo"
          @onConvertToHoliday="onConvertToHoliday"
        />
      </div>
    </div>

    <AttendanceLogsModal
      v-if="showLogdate"
      :student_number="programmeModel.student.student_number"
      :date="showLogdate.attendance_list.date"
      :att-enrolment="showLogdate"
      @close="showLogdate = null"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import AttendanceLogsModal from '@/pages/Dashboard/Academic/Attendances/AttendanceLogsModal.vue';

export default {
  components: {
    VueTable,
    AttendanceLogsModal,
  },
  props: {
    programmeModel: {
      type: Object,
      default: null,
    },
    studentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showLogdate: null,
      course_progress: null,
      vTable: {
        headers: [
          {
            title: 'attendance_list.classroom.classroom_code',
            mask: 'classroom',
            sortable: true,
          },
          {
            title: 'attendance_list.date',
            mask: 'date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'first_class',
            mask: '1st Class',
            sortable: true,
            checkeable: true,
          },
          {
            title: 'second_class',
            mask: '2nd Class',
            sortable: true,
            checkeable: true,
          },
          {
            title: 'status',
            mask: 'status',
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Convert into Holiday',
            callback: 'onConvertToHoliday',
            icon: 'refresh',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Attendance Logs',
            callback: 'onCheckLogs',
            icon: 'info',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Go to Attendance List',
            callback: 'onGoTo',
            icon: 'chevron_right',
          },
        ],
        values: {},
      },
      editable: false,
    };
  },
  computed: {
    courseProgress() {
      return Math.trunc((parseFloat(this.course_progress.performed_days) * 10000)
        / parseInt(this.course_progress.total_course_days, 10)) / 100;
    },
  },
  mounted() {
    this.$refs.vtable.init();
    this.getProgrammeProgress();
  },
  methods: {
    getProgrammeProgress() {
      this.request(`${this.$API.STUDENTS + this.studentId}/${this.$API.COURSE_PROGRESS}`, 'get', null, ({ data }) => {
        const aux = !data.length
          ? null
          : data
            .filter((x) => parseInt(x.programme_id, 10) === parseInt(this.$route.params.id, 10));

        this.course_progress = aux.length ? aux[0].attendance : null;
      });
    },
    getAttendance(params) {
      const parameters = params;
      if (!parameters.sortBy) parameters.sortBy = 'attendance_list.date';
      if (!parameters.sortDir) parameters.sortDir = 'desc';

      this.request(this.$API.PROGRAMMES + this.$route.params.id + '/attendances'.paginableUrl(parameters),
        'get', null, ({ data }) => {
          this.vTable.values = data;
          this.vTable.values.data = this.vTable.values.data.map((x) => {
            x.editable = true;
            return x;
          });
        });
    },
    onCheckLogs(item) {
      this.showLogdate = item;
    },
    onConvertToHoliday({ attendance_enrolment_id }) {
      const programme_id = this.$route.params.id;

      this.requestAsync(`programmes/${programme_id}/attendances/${attendance_enrolment_id}/to_holiday`, 'put')
        .then((data) => {
          this.fireSuccess(data);
          this.$refs.vtable.init();
          this.getProgrammeProgress();
        });
    },
    onGoTo(item, isMiddleClick) {
      this.openLink({
        name: 'AttendanceDetails',
        params: {
          id: item.attendance_list_id,
        },
      }, isMiddleClick);
    },
    onItemChecked(item) {
      this.request(`${this.$API.PROGRAMMES + this.$route.params.id}/attendances/${item.attendance_enrolment_id}`,
        'put', { ...item }, () => {
          this.fireSuccess('Attendance updated succesfully!');

          this.getProgrammeProgress();
        });
    },
  },
};
</script>

<style scoped>

</style>
